import { IconButton, makeStyles, Menu, Tab, Tabs, TabsProps, Theme, useMediaQuery } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  navTabs: {
    marginLeft: theme.spacing(2),
  },
}));

export function NavigationTabs(props: TabsProps)
{
  const { children } = props;
  const classes = useStyles();
  const [position, setPosition] = React.useState(-1);
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) =>
  {
    const anchor = ((event.target as HTMLElement).ownerDocument || document).querySelectorAll("#nav-section")[newValue];
    if(anchor)
    {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  useEffect(() =>
  {
    const handleScroll = () =>
    {
      let sections = document.querySelectorAll(`#top,div[id^=nav-section]`);
      let index = 0;
      let closestY = Infinity;
      for(let i = 0; i < sections.length; i++)
      {
        const rect = sections[i].getBoundingClientRect();
        if(Math.abs(rect.y) < closestY)
        {
          index = i;
          closestY = Math.abs(rect.y);
        }
      }
      setPosition(index - 1);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  });


  function tabs(props: TabsProps)
  {
    return (
      <Tabs {...props}
        onChange={handleTabChange}
        className={classes.navTabs}
        variant="scrollable"
        scrollButtons="auto"
      >
        {children}
      </Tabs>
    )
  }

  if(useMediaQuery('(min-width:768px)'))
  {
    return tabs({
      value: position,
      ...props
    });
  } else
  {
    const handleMenu = (event: React.MouseEvent<HTMLElement>) =>
    {
      const anchor = ((event.target as HTMLElement).ownerDocument || document).querySelector(
        '#app-bar'
      );
      if(anchor)
      {
        setMenuAnchor(anchor as HTMLElement);
      } else
      {
        setMenuAnchor(event.currentTarget);
      }
    };
    const open = Boolean(menuAnchor);
    const handleClose = () =>
    {
      setMenuAnchor(null);
    };
    const handleClick = () =>
    {
      setTimeout(handleClose, 800);
    };
    return (
      <>
        <IconButton edge="end" color='inherit' onClick={handleMenu}>
          <MenuIcon />
        </IconButton>
        <Menu
          id='menu-appbar'
          anchorEl={menuAnchor}
          anchorOrigin={
            {
              vertical: 'top',
              horizontal: 'right',
            }
          }
          transformOrigin={
            {
              vertical: 'top',
              horizontal: 'right',
            }
          }
          open={open}
          onClose={handleClose}
          onClick={handleClick}
          keepMounted
        >
          {tabs({ orientation: 'vertical', ...props })}
        </Menu>
      </>
    )
  }
}

interface LinkTabProps
{
  label?: string;
  href?: string;
}

export function LinkTab(props: LinkTabProps)
{
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
      {
        event.preventDefault();
      }}
      {...props}
    />
  )
}

