import { PaletteType } from '@material-ui/core';
import useMedia from './useMedia';

const colorSchemes = {
  dark: '(prefers-color-scheme: dark)',
  light: '(prefers-color-scheme: light)'
}

export default function (defaultScheme: PaletteType = 'light'): PaletteType
{
  return useMedia(colorSchemes, defaultScheme) as PaletteType;
}
