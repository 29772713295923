import { useEffect, useState } from 'react';

interface IQueries
{
  [value: string]: string;
}

export default function (queries: IQueries, defaultValue: string)
{
  const [currentValue, setCurrentValue] = useState(defaultValue);

  useEffect(() =>
  {
    if (!window.matchMedia) { return }

    const listener = (e: MediaQueryListEvent) =>
    {
      if (!e || !e.matches) { return }

      const values = Object.keys(queries)
      for (const value of values)
      {
        if (e.media === queries[value])
        {
          setCurrentValue(value)
          break;
        }
      }
    }

    let activeMatches: MediaQueryList[] = []
    Object.keys(queries).forEach(value =>
    {
      const mq = window.matchMedia(queries[value])
      mq.addListener(listener)
      activeMatches.push(mq)
      listener(mq as any as MediaQueryListEvent)
    })

    return () =>
    {
      activeMatches.forEach(mq => mq.removeListener(listener))
      activeMatches = []
    }
  }, [queries])

  return currentValue
}
