import { Button, CardContent, createMuiTheme, Grid, Link, List, ListItem, ListItemIcon, ListItemProps, ListItemText, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import MailIcon from '@material-ui/icons/Mail';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import BackgroundImage from '../../bg.jpg';
import { ReactComponent as MainLogo } from '../../logo.svg';
import { CardAdaptive, PaperAdaptive } from '../../shared/Adaptive';
import usePaletteType from "../../shared/Hooks/usePaletteType";
import { ServiceWorkerState } from '../../shared/Hooks/useServiceWorker';
import LayoutBasic from '../../shared/LayoutBasic';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },

  mainContainer: {
    textAlign: 'center',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: "2em 0 4em 0",
    animation: `$slide-in-blurred-top 1.2s cubic-bezier(0.230, 1.000, 0.320, 1.000) both`,
    userSelect: 'none',
  },

  mainLogo: {
    padding: "2em 0 2em 0",
    maxHeight: "25em",
    filter: `drop-shadow(5px 5px 5px rgba(0, 0, 0, .33))`,
    width: "90%",
    "& #Text path": {
      fill: "black",
    },
    "& #Cloud path": {
      fill: theme.palette.primary.main,
    }
  },

  list: {
    maxWidth: '40em',
    textAlign: 'center',
    display: 'inline-block'
  },

  "@keyframes slide-in-blurred-top": {
    "0%": {
      transform: "translateY(-500px) scaleY(2.5) scaleX(0.2)",
      "transform- origin": "50% 0%",
      filter: "blur(40px)",
      opacity: 0,
    },
    "100%": {
      transform: "translateY(0) scaleY(1) scaleX(1)",
      "transform-origin": "50% 50 %",
      filter: "blur(0)",
      opacity: 1,
    }
  },
  "@keyframes slide-in-top": {
    "0%": {
      transform: "translateY(-1000px)",
      opacity: 0,
    },
    "100%": {
      transform: "translateY(0)",
      opacity: 1,
    },
  },
  subTitle: {
    color: "black",
    margin: '0px 48px 0px 48px',
  },
  bulletedList: {
    "& li:not(last-child)": {
      marginBottom: theme.spacing(1),
    }
  },
  cardContent: {
    padding: `${theme.spacing(2)}px 0px 0px 0px`,
  },
  languages: {
    marginTop: theme.spacing(2),
    "& button": {
      textTransform: 'none',
    }
  },
}));

function ListItemLink(props: ListItemProps<'a', { button?: true }>)
{
  return <ListItem button component="a" {...props} />;
}
export default function Component(props: {
  serviceWorkerState: ServiceWorkerState,
  env: string
})
{
  const { t, i18n } = useTranslation();

  const classes = useStyles();

  const paletteType = usePaletteType();
  const appBarPalettes = {
    primary: {
      dark: {
        contrastText: grey[300],
        main: '#35363a',
      },
      light: {
        contrastText: 'white',
        main: '#e91f64',
      },
    },
    secondary: {
      dark: { main: '#e91f64' },
      light: { main: '#fff' },
    },
    text: {
      primary: {
        dark: grey[300],
        light: '#000',
      },
    },
  }

  const scrollTopPalettes = {
    secondary: {
      dark: { main: '#e91f64' },
      light: { main: '#e91f64' }
    }
  }

  const appBarTheme = createMuiTheme({
    palette: {
      primary: appBarPalettes.primary[paletteType],
      secondary: appBarPalettes.secondary[paletteType],
      text: {
        primary: appBarPalettes.text.primary[paletteType],
      },
      type: paletteType,
    },
    typography: {},
  });

  const scrollTopTheme = createMuiTheme({
    palette: {
      primary: appBarPalettes.primary[paletteType],
      secondary: scrollTopPalettes.secondary[paletteType],
      text: {
        primary: appBarPalettes.text.primary[paletteType],
      },
      type: paletteType,
    },
    typography: {},
  });

  useEffect(() =>
  {
    if(props.serviceWorkerState === ServiceWorkerState.UpdateAvailable)
    {
      (async () =>
      {
        const registration = await navigator.serviceWorker.ready;
        await registration.unregister();
        window.location.reload();
      })();
    }
  }, [props.serviceWorkerState]);

  const languages: { [x: string]: { nativeName: string } } = {
    en: { nativeName: 'English' },
    hu: { nativeName: 'Magyar' }
  };

  useEffect(() =>
  {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <LayoutBasic
      env={props.env}
      appBarTheme={appBarTheme}
      scrollTopTheme={scrollTopTheme}
      navigationBarEntries={[t('development.menu'), t('solutions.menu'), t('contact.menu')]}
      footer={
        <div className={classes.languages}>
          {Object.keys(languages).map((lng) => (
            <Button size="small" key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} onClick={() =>
            {
              i18n.changeLanguage(lng);
            }}>
              {languages[lng].nativeName}
            </Button>
          ))}
        </div>
      }
    >
      <MetaTags>
        <meta name="description" content={t('description')} />
        <meta name="theme-color" media="(prefers-color-scheme: light)" content="#e91f64" />
        <meta name="theme-color" media="(prefers-color-scheme: dark)" content="#35363a" />
        {Object.keys(languages).map((lng) => (
          <link rel="alternate" hrefLang={lng} href={`https://www.pinkcloud.hu/?lng=${lng}`} />
        ))}
        <link rel="preload" href="/static/media/bg.cbe0124e.jpg" as="image" />
        <link rel="preload" href="/static/media/roboto-latin-ext-400.e5d046dd.woff2" as="font" type="font/woff2" />
        <link rel="preload" href="/static/media/roboto-latin-ext-700.53eedb1e.woff2" as="font" type="font/woff2" />
        <link rel="preload" href="/static/media/roboto-latin-ext-500.89044585.woff2" as="font" type="font/woff2" />
      </MetaTags>
      <Paper className={classes.mainContainer} square>
        <MainLogo className={classes.mainLogo} />
        <Typography variant="h4" className={classes.subTitle}>{t('title')}</Typography>
      </Paper>

      <PaperAdaptive titleString={t('development.title')}>
        <Typography variant="body1">
          {t('development.body')}
        </Typography>
      </PaperAdaptive>

      <PaperAdaptive titleString={t('solutions.title')}>
        <Grid container spacing={3} justify="space-evenly">

          <Grid item xs={12} md={6} lg={4}>
            <CardAdaptive>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5">{t('technology.title')}</Typography>
                <Typography variant="body1">
                  {t('technology.body')}
                </Typography>
                <Typography variant="body2">
                  <ul className={classes.bulletedList}>
                    <li>{t('technology.item1')}</li>
                    <li>
                      <Trans i18nKey="technology.item2"
                        components={{
                          scl: <Link href="https://azure.microsoft.com/overview/serverless-computing/" target="_blank" rel="noopener noreferrer" /> // eslint-dis able-line jsx-a11y/anchor-has-content
                        }}
                      />
                    </li>
                  </ul>
                </Typography>
              </CardContent>
            </CardAdaptive>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <CardAdaptive>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5">{t('operation.title')}</Typography>
                <Typography variant="body1">
                  {t('operation.body')}
                </Typography>
                <Typography variant="body2">
                  <ul className={classes.bulletedList}>
                    <li>{t('operation.item1')}</li>
                    <li>{t('operation.item2')}</li>
                  </ul>
                </Typography>
              </CardContent>
            </CardAdaptive>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <CardAdaptive>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5">{t('integration.title')}</Typography>
                <Typography variant="body1">
                  {t('integration.body')}
                </Typography>
                <Typography variant="body2">
                  <ul className={classes.bulletedList}>
                    <li>{t('integration.item1')}</li>
                    <li>{t('integration.item2')}</li>
                    <li>{t('integration.item3')}</li>
                  </ul>
                </Typography>
              </CardContent>
            </CardAdaptive>
          </Grid>

        </Grid>
      </PaperAdaptive>

      <PaperAdaptive titleString={t('contact.title')}>
        <Typography variant="h5" component="h2">{t('contact.name')}</Typography>
        <List className={classes.list}>
          <ListItemLink href={`mailto:${t('contact.email')}`}>
            <ListItemIcon> <MailIcon /> </ListItemIcon>
            <ListItemText primary={t('contact.email')} />
          </ListItemLink>
          <ListItemLink href={`tel:${t('contact.phone.link')}`}>
            <ListItemIcon> <SmartphoneIcon /> </ListItemIcon>
            <ListItemText primary={t('contact.phone.label')} />
          </ListItemLink>
        </List>
      </PaperAdaptive>

    </LayoutBasic>
  );
}
