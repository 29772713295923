import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createHashHistory } from 'history';
import { AppConfig } from '.';

export default function (appConfig: AppConfig)
{
  const browserHistory = createHashHistory({ basename: '' });
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: appConfig.aiik ? appConfig.aiik : process.env.REACT_APP_INSTRUMENTATION_KEY,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory }
      }
    }
  });
  appInsights.loadAppInsights();
  return { reactPlugin, appInsights };
}
