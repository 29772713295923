import { useEffect, useState } from 'react';
import * as serviceWorker from '../../serviceWorker';

export enum ServiceWorkerState { Unknown, Installed, Ready, UpdateAvailable }

export default function (): ServiceWorkerState
{
  const [state, setState] = useState(ServiceWorkerState.Unknown);

  useEffect(() =>
  {
    serviceWorker.register({
      onSuccess: () => setState(ServiceWorkerState.Installed),
      onReady: () => setState(ServiceWorkerState.Ready),
      onUpdate: () => setState(ServiceWorkerState.UpdateAvailable),
    });

    return () =>
    {
    }
  }, [])

  return state
}
