import { AppBar, createMuiTheme, Fab, Paper, Theme, ThemeProvider, Toolbar, Tooltip, Typography, useScrollTrigger, Zoom, ZoomProps } from '@material-ui/core';
import { grey, pink } from '@material-ui/core/colors';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';
import { ReactComponent as BottomLogo } from '../pinkCloudLogo.svg';
import { ReactComponent as SmallLogo } from '../smallLogo.svg';
import usePaletteType from './Hooks/usePaletteType';
import { LinkTab, NavigationTabs } from './NavigationTabs';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    animation: "$slide-in-top 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
    userSelect: 'none',
  },

  smallLogo: {
    height: "1em",
    marginRight: "0.75em",
    "& #Fill *": {
      fill: theme.palette.secondary.main,
    }
  },

  fullWidthLogo: {
    height: "2em",
    "& #Fill *": {
      fill: theme.palette.secondary.main,
    }
  },

  grow: {
    flexGrow: 1,
  },

  buildnumber: {
    fontSize: 10
  },

  scrollTop: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    '& button': {
      '@media (max-width:420px)': {
        width: '36px',
        height: '36px',
      }
    }
  },

  footer: {
    textAlign: 'center',
    padding: "2em 0 4em 0",
    userSelect: 'none'
  },

  bottomLogo: {
    padding: "1.2em 0 1.2em 0",
    margin: "0 1.2em 0 1.2em",
    maxHeight: "8em",
    "& #Text path": {
      fill: theme.palette.text.primary,
    }
  },
}));

function NavigationBar(props: { tabs: string[] })
{
  if(props.tabs.length === 0) return <> </>
  return (
    <NavigationTabs>
      {props.tabs.map((t, i) => <LinkTab label={t} key={i} />)}
    </NavigationTabs>
  )
}
function ScrollTop(props: ZoomProps)
{
  const { children } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 500,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) =>
  {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      '#top',
    );

    if(anchor)
    {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }


  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.scrollTop}>
        {children}
      </div>
    </Zoom>
  );
}

const OptionalThemeProvider: FunctionComponent<{ theme?: Theme }> = props =>
{
  if(props.theme !== undefined)
  {
    return (
      <ThemeProvider theme={props.theme}>
        {props.children}
      </ThemeProvider>
    )
  }
  return <>{props.children}</>
}

const Component: FunctionComponent<{
  navigationBarEntries: string[],
  env: string,
  appBarTheme?: Theme,
  scrollTopTheme?: Theme,
  fullWidthLogo?: boolean,
  footer?: JSX.Element
}> = props =>
  {
    const classes = useStyles();
    const paletteType = usePaletteType();

    const pinkCloudLogoPalette = {
      primary: {
        dark: {
          contrastText: grey[900],
          main: pink[500],
        },
        light: {
          contrastText: 'white',
          main: pink[500],
        },
      }
    }
    const pinkCloudLogoTheme = createMuiTheme({
      palette: {
        primary: pinkCloudLogoPalette.primary[paletteType],
        type: paletteType,
      }
    });

    const appBarText = () =>
    {
      if(props.fullWidthLogo)
      {
        return <>
          <SmallLogo className={classes.fullWidthLogo} />
          <Typography variant="h6" noWrap className={classes.grow} />
        </>
      } else
      {
        return <>
          <SmallLogo className={classes.smallLogo} />
          <Typography variant="h6" noWrap className={classes.grow}>
            {process.env.REACT_APP_TITLE}
          </Typography>
        </>
      }
    }

    return (
      <>
        <OptionalThemeProvider theme={props.appBarTheme}>
          <AppBar id="app-bar" position="sticky" className={classes.appBar}>
            <Toolbar variant="dense">
              {appBarText()}
              <NavigationBar tabs={props.navigationBarEntries} />
            </Toolbar>
          </AppBar>
        </OptionalThemeProvider>
        <div id="top" />
        {props.children}

        <Paper className={classes.footer} square>
          <ThemeProvider theme={pinkCloudLogoTheme}>
            <a href="http://pinkcloud.hu/">
              <BottomLogo className={classes.bottomLogo} />
            </a>
          </ThemeProvider>
          <div className={classes.buildnumber}>
            <Tooltip title="Környezet"><span>{props.env}: </span></Tooltip>
            <Tooltip title="Verziószám"><span>{process.env.REACT_APP_BUILDNUMBER} </span></Tooltip>
          </div>
          {props.footer}
        </Paper>

        <OptionalThemeProvider theme={props.scrollTopTheme}>
          <ScrollTop>
            <Fab color="secondary">
              <KeyboardArrowUpIcon />
            </Fab>
          </ScrollTop>
        </OptionalThemeProvider>
      </>
    )
  }

export default Component;
