import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { AppConfig } from "."; 
import useServiceWorker from './shared/Hooks/useServiceWorker';

// ««·· imports
import './i18n';
import Home from "./routes/Home";
// ··»»

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
export default function Component(props: { appConfig: AppConfig })
{
  // eslint-disable-next-line
  const serviceWorkerState = useServiceWorker();
  

  // ««·· render
  // ··»»

  return (
    <HashRouter basename={baseUrl!}>
      <Switch>
        {/* ««·· routes */}
        <Route exact path="/">
          <Home env={props.appConfig.env} serviceWorkerState={serviceWorkerState}/>
        </Route>
        {/* # ··»» */}
        
      </Switch>
    </HashRouter>
  );
}
