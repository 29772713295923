import { Card, CardProps, makeStyles, Paper, Theme, useMediaQuery, useTheme, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";

const useStyles = makeStyles((theme: Theme) => (
  {
    section: {
      textAlign: 'center',
      overflowX: 'hidden',
      padding: '5em 2em 5em 2em',
      '@media (max-width:600px)': {
        padding: '5em 0em 5em 0em'
      },
      margin: '0 0 0.5rem 0',
      [theme.breakpoints.up("md")]: {
        margin: '1.2rem'
      },
      '& h1': {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        marginBottom: '2rem'
      },
      '& h5': {
        marginBottom: '0.5em',
        [theme.breakpoints.down("md")]: {
          fontSize: '1.25rem'
        },
      },
      '& p': {
        display: 'inline-block',
        hyphens: 'auto',
        textAlign: 'justify',
        textJustify: 'inter-word',
        margin: '0 2rem 0 2rem',
        maxWidth: '40em'
      },
      '& ul': {
        paddingLeft: '20px'
      }
    },
  }));

export const PaperAdaptive: FunctionComponent<{ titleString?: string }> = props =>
{
  const theme = useTheme();
  const classes = useStyles();
  let titleElement = <> </>;
  if (props.titleString) {
    titleElement = (
      <Typography variant="h1">{props.titleString}</Typography>
    )
  }
  return (
    <Paper className={classes.section} id="nav-section" {...props} square={!useMediaQuery(theme.breakpoints.up('md'))}>
      {titleElement}
      {props.children}
    </Paper>
  )
}

export function CardAdaptive(props: CardProps)
{
  if(useMediaQuery('(min-width:600px)'))
  {
    return <Card {...props} variant="outlined" />
  } else
  {
    return <Card {...props} variant="elevation" square />
  }
}
