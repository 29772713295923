import { CssBaseline } from '@material-ui/core';
// eslint-disable-next-line
import { blue, cyan, grey, pink } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto-multilang/latin-ext.css';
import App from './App';
import usePaletteType from './shared/Hooks/usePaletteType';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import useAppInsights from "./useAppInsights";
export interface AppConfig
{
  uri: string,
  env: string,
  aiik: string,
}

const Index: FunctionComponent<{ appConfig: AppConfig }> = (props) =>
{
  const paletteType = usePaletteType();
  const { reactPlugin } = useAppInsights(props.appConfig);

  // ««·· palette
  const palettes = {
    primary: {
      dark: {
        contrastText: grey[900],
        main: pink[500],
      },
      light: {
        contrastText: 'white',
        main: pink[500],
      },
    },
    secondary: {
      dark: { main: pink[500] },
      light: { main: '#FFF' },
    },
    text: {
      primary: {
        dark: grey[300],
        light: '#000',
      },
    },
  }
  // ··»»

  // ««·· theme
  const theme = createMuiTheme({
    palette: {
      primary: palettes.primary[paletteType],
      secondary: palettes.secondary[paletteType],
      text: {
        primary: palettes.text.primary[paletteType],
      },
      type: paletteType,
    },
    typography: {
    },
  });
  theme.typography.h1 = {
    fontSize: '1.5rem',
    '@media (min-width:600px)': {
      fontSize: '2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3.2rem'
    }
  };
  theme.typography.h4 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem'
    }
  };
  theme.typography.h5 = {
    fontSize: '1rem',
    '@media (min-width:600px)': {
      fontSize: '1.2rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem'
    }
  };
  // ··»»

  
  return (
    <React.StrictMode>
      <AppInsightsContext.Provider value={reactPlugin}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App appConfig={props.appConfig} />
          </ThemeProvider>
      </AppInsightsContext.Provider>
    </React.StrictMode>
  );
}

async function main()
{
  const response = await fetch('/app.config.json');
  const config = await response.json() as AppConfig;
  ReactDOM.render(<Index appConfig={config} />, document.getElementById('root'));
}

main();
